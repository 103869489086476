export const webApi = {
  // domain: 'http://localhost:7090', // development
  // domain: 'https://devapi.bookyourslot.co', //Dev Link
  domain: 'https://api.bookyourslot.co', //Production Link

  endPoint: {

    // Admin Dashboard
    fetchDetailsBasedOnInterval:
      '/api/adminDashboard/fetchDetailsBasedOnInterval',
    fetchSportBookingPercentage:
      '/api/adminDashboard/fetchSportBookingPercentage',

    // General Info APIs
    createInfo: '/api/appGeneralInfo/createInfo',
    updateInfo: '/api/appGeneralInfo/updateInfo',
    fetchInfo: '/api/appGeneralInfo/fetchInfo',

    // Business APIcreateUserAndBusinessAndTurfs
    createAndEditUserAndBusiness: '/api/business/createAndEditUserAndBusiness',
    fetchApprovedBusiness: '/api/business/fetchApprovedBusiness',
    fetchAllBusiness: '/api/business/fetchAllBusiness',
    verifyAndUnVerifyBusiness: '/api/business/verifyAndUnVerifyBusiness',
    suspendBusiness: '/api/business/suspendBusiness',
    deleteBusiness: '/api/business/deleteBusiness',

    // Turfs APIs
    createAndEditTurf: '/api/turf/createAndEditTurf',
    fetchAllTurf: '/api/turf/fetchAllTurf',
    verifyAndUnVerifyTurf: '/api/turf/verifyAndUnVerifyTurf',
    suspendTurf: '/api/turf/suspendTurf',
    enableWhatsApp: '/api/turf/enableWhatsApp',
    deleteTurf: '/api/turf/deleteTurf',
    fetchTurfAdmin: '/api/turf/fetchTurfAdmin',
    addEditConvenienceFee: '/api/turf/addEditConvenienceFee',
    createDynamicLinksFoVenue: '/api/turf/createDynamicLinksFoVenue',

    // Sports
    fetchSport: '/api/sport/fetchSport',
    insertAndUpdateSport: '/api/sport/insertAndUpdateSport',
    updateActiveStatus: '/api/sport/updateActiveStatus',
    deleteSport: '/api/sport/deleteSport',
    fetchAllSports: '/api/sport/fetchAllSports',

    // Sports Category
    fetchSportCategory: '/api/sportCategory/fetchSportCategory',

    // Booking
    searchBookingFromAdmin: '/api/booking/searchBookingFromAdmin',
    fetchParticularDayAllBooking: '/api/booking/fetchParticularDayAllBooking',
    fetchFewBooking: '/api/booking/fetchFewBooking',
    fetchAllBooking: '/api/booking/fetchAllBooking',
    fetchBusinessBooking: '/api/booking/fetchBusinessBooking',
    fetchUserBooking: '/api/booking/fetchUserBooking',
    cancelAndRescheduleBooking: '/api/booking/cancelAndRescheduleBooking',
    sendUserMsgManually: '/api/booking/sendUserMsgManually',
    sendOwnerMsgManually: '/api/booking/sendOwnerMsgManually',

    //RENTAL
    fetchRentalItem: '/api/rental/fetchRentalItem',
    deleteRentalItem: '/api/rental/deleteRentalItem',
    addAndEditRentalItem: '/api/rental/addAndEditRentalItem',

    //COUUPON
    fetchAllCouponsForAdminDashboard: '/api/coupon/fetchAllCouponsForAdminDashboard',
    createAndUpdateCoupon: '/api/coupon/createAndUpdateCoupon',
    deleteCoupon: '/api/coupon/deleteCoupon',

    // Report APIs
    generateUserRegistrationReport:
      '/api/adminreport/generateUserRegistrationReport',
    generateLastActiveReport: '/api/adminreport/generateLastActiveReport',
    generateBookingReport: '/api/adminreport/generateBookingReport',
    fetchAllReport: '/api/adminreport/fetchAllReport',
    fetchAllBusinessForReport: '/api/adminreport/fetchAllBusinessForReport',
    fetchAllTurfForReport: '/api/adminreport/fetchAllTurfForReport',



    // App Config
    fetchAllRoutes: '/api/appConfig/fetchAllRoutes',

    // //Campaign
    fetchAllFleetOwnerForCampaign:
      '/api/campaign/fetchAllFleetOwnerForCampaign',
    fetchCampaign: '/api/campaign/fetchCampaign',
    getTargetCount: '/api/campaign/getTargetCount',
    changeCampaignStatus: '/api/campaign/changeCampaignStatus',
    createAndUpdateCampaign: '/api/campaign/createAndUpdateCampaign',
    fetchCampaignDeliveryReport: '/api/campaign/fetchCampaignDeliveryReport',
    fetchAllCourse: '/api/campaign/fetchAllCourse',


    // employee routes
    getEmployeeData: '/api/bysemployee/getEmployeeData',
    fetchAllEmployees: '/api/bysemployee/fetchAllEmployee',
    fetchEmployeeDetails: '/api/bysemployee/fetchEmployeeDetailsWithId',
    addAndEditEmployees: '/api/bysemployee/addAndEditEmployee',
    deleteEmployee: '/api/bysemployee/deleteEmployee',
    updateEmployeeStatus: '/api/bysemployee/updateEmployeeStatus',
    isValidEmployeeWithPassword: '/api/bysemployee/isValidEmployeeWithPassword',

    // Withdrawal
    fetchAllWithdrawal: '/api/withdrawal/fetchAllWithdrawal',
    searchWithdrawal: '/api/withdrawal/searchWithdrawal',

    // CRM
    searchVenueByNameAndLocation: '/api/crm/searchVenueByNameAndLocation',
    searchUserByNameAndPhone: '/api/crm/searchUserByNameAndPhone',
    fetchBookingDetailsOfUser: '/api/crm/fetchBookingDetailsOfUser',
    fetchTurfBookingSlot: '/api/crm/fetchTurfBookingSlot',
    fetchBlockedSlot: '/api/crm/fetchBlockedSlot',
    createBookingAndSendPaymentLink: '/api/crm/createBookingAndSendPaymentLink',
    fetchCRMCoupon: '/api/crm/fetchCRMCoupon',

    //  Contest Video
    deleteParticipant: '/api/contestParticipant/deleteParticipant',
    publishUnpublishContestParticipant: '/api/contestParticipant/publishUnpublishContestParticipant',

    //  Contest
    addEditContest: '/api/contest/addEditContest',
    fetchAllContest: '/api/contest/fetchAllContest',
    publishUnpublishContest: '/api/contest/publishUnpublishContest',
    deleteContest: '/api/contest/deleteContest',
    fetchParticularContestAllVideo: '/api/contest/fetchParticularContestAllVideo',
    downloadParticipantList: '/api/contest/downloadParticipantList',
    updateContestStatus: '/api/contest/updateContestStatus',
    setRandomWinner: '/api/contest/setRandomWinner',
    updateContestWinnerVideo: '/api/contest/updateContestWinnerVideo',
    createDynamicLinksForContest: '/api/contest/createDynamicLinksForContest',
    fetchContestByCategory: '/api/contest/fetchContestByCategory',

    //Contest Category
    createAndUpdateContestCategory: '/api/contest/createAndUpdateContestCategory',
    activeOrDeleteContestCategory: '/api/contest/activeOrDeleteContestCategory',
    fetchContestCategories: '/api/contest/fetchContestCategories',

  },
};



export const WA_API = {
  // domain: 'http://localhost:8040', //DEV
  domain: 'https://wawebhook.buildby.me', //PROD

  endPoint: {
    //TEMPLATES
    fetchAllTemplates: '/api/template/fetchAllTemplates',
    syncAllTemplates: '/api/template/syncAllTemplates',

    //CHATS
    fetchAllChats: '/api/chats/fetchAllChats',
    searchChat: '/api/chats/searchChat',

    //MESSAGES
    fetchMessagesByChatId: '/api/message/fetchMessagesByChatId',
    fetchMessageDetailByCampaignId:
      '/api/message/fetchMessageDetailByCampaignId',
    fetchActiveUserCountByClientId:
      '/api/message/fetchActiveUserCountByClientId',

    //Client
    getClientDetail: '/api/client/getClientDetail',

    //Transaction
    createOrder: '/api/transaction/createOrder',
    rechargeClient: '/api/transaction/rechargeClient',
    updateStatusOfClientPayment: '/api/transaction/updateStatusOfClientPayment',
  },
};
