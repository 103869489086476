import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminRoutingModule } from './admin-routing.module';
import { SidebarModule } from 'ng-sidebar';
import { AdminComponent } from './admin.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { SortDirectiveForAdmin } from './sort.directive';
import { BusinessModule } from './business/business.module';
import { TurfModule } from './turf/turf.module';
import { ComplianceModule } from './compliance/compliance.module';
import { BookingModule } from './booking/booking.module';
import { MainRentalComponent } from './main-rental/main-rental.component';
import { MainRentalModule } from './main-rental/main-rental.module';
import { OfferManagementModule } from './offer-management/offer-management.module';
import { SportManagementComponent } from './sport-management/sport-management.component';
import { SportManagementModule } from './sport-management/sport-management.module';
import { AuthFormComponent } from './1component/auth-form/auth-form.component';
import { AvatarComponent } from './1component/avatar/avatar.component';
import { BadgeComponent } from './1component/badge/badge.component';
import { BarChartComponent } from './1component/bar-chart/bar-chart.component';
import { BasicTableComponent } from './1component/basic-table/basic-table.component';
import { ButtonGroupComponent } from './1component/button-group/button-group.component';
import { ButtonComponent } from './1component/button/button.component';
import { CardComponent } from './1component/card/card.component';
import { DataTableComponent } from './1component/data-table/data-table.component';
import { DropdownComponent } from './1component/dropdown/dropdown.component';
import { FormChecksComponent } from './1component/form-checks/form-checks.component';
import { FormGroupComponent } from './1component/form-group/form-group.component';
import { IconComponent } from './1component/icon/icon.component';
import { InputGroupComponent } from './1component/input-group/input-group.component';
import { PieChartComponent } from './1component/pie-chart/pie-chart.component';
import { StepperComponent } from './1component/stepper/stepper.component';
import { TagComponent } from './1component/tag/tag.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { ReportsModule } from './reports/reports.module';
import { CampaignModule } from './campaign/campaign.module';
import { EmployeeManagementModule } from './employee-management/employee-management.module';
import { WithdrawalModule } from './withdrawal/withdrawal.module';
import { CrmModule } from './crm/crm.module';
import { ContestComponent } from './contest/contest.component';
import { ContestModule } from './contest/contest.module';

@NgModule({
  declarations: [
    AdminComponent,
    SortDirectiveForAdmin,
    AdminDashboardComponent,


    LeftSidebarComponent,
    HeaderComponent,
    FooterComponent,

    AvatarComponent,
    BadgeComponent,
    TagComponent,
    ButtonComponent,
    ButtonGroupComponent,
    FormGroupComponent,
    InputGroupComponent,
    FormChecksComponent,
    AuthFormComponent,
    DropdownComponent,
    IconComponent,
    CardComponent,
    BasicTableComponent,
    DataTableComponent,
    PieChartComponent,
    BarChartComponent,
    StepperComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    OfferManagementModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    AdminRoutingModule,
    NgMultiSelectDropDownModule,
    SidebarModule.forRoot(),
    NgxPaginationModule,
    BusinessModule,
    TurfModule,
    ComplianceModule,
    BookingModule,
    MainRentalModule,
    ReportsModule,
    WithdrawalModule,
    SportManagementModule,
    EmployeeManagementModule,
    CampaignModule,
    CrmModule,
  ],

})
export class AdminModule { }
