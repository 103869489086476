import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { webApi } from './api';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  // Variables

  typeOfReport: any;
  listOfBusinessForReport: any;
  listOfTurfForReport: any;

  campaignEdit: any;
  campaignDetailToView: any;

  selectedBusiness: any;

  listOfCities: any = [];
  validUsersDetail: any;
  usersDetailForSignUp: any;
  courseDetailToEdit: any;
  moduleDetailForActivity: any;
  userId: String = '';
  listOfQuizToEditFromQuizSection: any;
  questionToAccessFromComment: any;
  listOfReferralsUser: any;
  userDetails: any;
  userInfo: any;
  // sidebarOptions: any = [];

  sidebarOptions: any = [
    {
      title: 'Dashboard',
      svgUrl: '../assets/svgIcons/analytics.svg',
      route: '/admin/dashboard',
      isSelected: false,
    },
    {
      title: 'Booking',
      svgUrl: '../assets/svgIcons/Booking.svg',
      route: '/admin/booking',
      isSelected: false,
    },
    {
      title: 'Business Management',
      svgUrl: '../assets/svgIcons/turf.svg',
      route: '/admin/business',
      isSelected: false,
    },
    {
      title: 'Venue Management',
      svgUrl: '../assets/svgIcons/location1.svg',
      route: '/admin/turf',
      isSelected: false,
    },
    {
      title: 'Rentals',
      svgUrl: '../assets/svgIcons/package.svg',
      route: '/admin/rental',
      isSelected: false,
    },
    {
      title: 'Offer Management',
      svgUrl: '../assets/svgIcons/coupon.svg',
      route: '/admin/offer',
      isSelected: false,
    },
    {
      title: 'Contests',
      svgUrl: '../assets/svgIcons/contest_outline.svg',
      route: '/admin/contest',
      isSelected: false,
    },
    {
      title: 'Sport Management',
      svgUrl: '../assets/svgIcons/Footballl.svg',
      route: '/admin/sport',
      isSelected: false,
    },
    {
      title: 'Employee',
      svgUrl: '../assets/svgIcons/user.svg',
      route: '/admin/employee',
      isSelected: false,
    },
    {
      title: 'CRM',
      svgUrl: '../assets/svgIcons/crm.svg',
      route: '/admin/crm',
      isSelected: false,
    },
    {
      title: 'Campaign Management',
      svgUrl: '../assets/svgIcons/bell.svg',
      route: '/admin/campaign',
      isSelected: false,
    },
    {
      title: 'Withdrawal',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/admin/withdrawal',
      isSelected: false,
    },
    {
      title: 'Compliance',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/admin/compliance',
      isSelected: false,
    },

    {
      title: 'Report',
      svgUrl: '../assets/svgIcons/tc.svg',
      route: '/admin/reports',
      isSelected: false,
    },
  ];
  sideBarCopy = [...this.sidebarOptions];

  userDetail: any;
  //constructor
  constructor(
    private _httpClient: HttpClient,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.userDetail = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    // if (this.userDetail != null) {
    //   if (this.userDetail['role'] == 'Admin') {
    //     this.sidebarOptions = this.sidebarOptionsForAdmin;
    //     this.sidebarOptions.forEach((ele: any, index: any) => {
    //       if (index == 0) {
    //         ele['isSelected'] = true;
    //       } else {
    //         ele['isSelected'] = false;
    //       }
    //     });
    //   }
    // }
  }
  get windowRef() {
    return window;
  }
  gotoDefault(userDetail: any) {
    if (userDetail['role'] == 'Admin') {
      this.sidebarOptions = this.sideBarCopy;
      this.sidebarOptions.forEach((ele: any, index: any) => {
        if (index == 0) {
          ele['isSelected'] = true;
        } else {
          ele['isSelected'] = false;
        }
      });
      this.router.navigate(['/admin/dashboard']);
    }
  }
  async sideBar() {
    this.userDetails = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);

    if (this.userDetails == null) {
      this.router.navigate(['/auth/login']);
      return false;
    } else {
      this.sidebarOptions = [...this.sideBarCopy];
      this.sidebarOptions.forEach((data: any) => {
        if (data != null && data != undefined) {
          data['isSelected'] = false;
        }
      });
      const param = {
        email: this.userDetails['email'].trim(),
      };
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${this.userDetails['accessToken']}`,
      });
      const URL = `${webApi.domain + webApi.endPoint.getEmployeeData}`;
      await this.postMethodWithToken(param, URL, httpHeaders).then((data: any) => {
        if (data['success']) {
          if (data['result'] != null) {
            this.userInfo = data['result'][0];
            var list: any = [];
            if (this.userInfo != null) {
              if (this.userInfo['employeeType'] == 'Employee') {
                if (this.userInfo['accessControlList'].length > 0) {
                  this.userInfo['accessControlList'].forEach((access: any) => {
                    var side = this.sidebarOptions.filter(
                      (sidebar: any) => sidebar['title'] == access
                    );
                    if (side[0] != undefined) {
                      list.push(side[0]);
                    }
                  });
                  this.sidebarOptions = [...list];
                  // this.gotoComponent(this.sidebarOptions [0]['subTitle']);
                }
              }
              return true;
            } else {
              this.router.navigate(['/auth/login']);
              return false;
            }
          }
        }
        return true;
      });
      return true;
    }
  }

  async setSideBarState(urlPath: any) {
    let valid = await this.sideBar();
    if (!valid) {
      return;
    }
    if (this.sidebarOptions.length == 0) {
      localStorage.removeItem('BYS-ADMIN-WEB');
      this.router.navigate(['/auth/login']);
      return;
    }
    if (urlPath == undefined) {
      this.sidebarOptions[0].isSelected = true;
    } else {
      let valid = this.checkIfUrlIsValid();
      if (!valid) {
        this.gotoComponent(this.sidebarOptions[0].title, 'Admin');
        this.sidebarOptions[0]['isSelected'] = true;
      } else {
        this.sidebarOptions.forEach((option: any) => {
          if (option != null && option != undefined) {
            if (option.route == '/' + urlPath[3] + '/' + urlPath[4]) {
              option['isSelected'] = true;
            }
          }
        });
      }
    }
  }

  checkIfUrlIsValid() {
    let urlPath = window.location.href.split('/');
    let index = this.sidebarOptions.findIndex(
      (data: any) => data['route'] == '/' + urlPath[3] + '/' + urlPath[4]
    );
    if (index == -1) {
      return false;
    } else {
      return true;
    }
  }
  updatdUserDetailFromLocalStorage() {
    this.userDetail = JSON.parse(localStorage.getItem('BYS-ADMIN-WEB')!);
    if (this.userDetail == null) {
      this.router.navigate(['/auth/login']);
    }
  }
  gotoComponent(title: String, role: any) {
    this.sidebarOptions.forEach((option: any) => {
      if (option['title'] == title) {
        option['isSelected'] = true;
      } else {
        option['isSelected'] = false;
      }
    });
    if (role == 'Admin') {
      switch (title) {
        case 'Dashboard':
          this.router.navigate(['/admin/dashboard']);
          break;
        case 'Booking':
          this.router.navigate(['/admin/booking']);
          break;
        case 'Report':
          this.router.navigate(['/admin/reports']);
          break;
        case 'Business Management':
          this.router.navigate(['/admin/business']);
          break;
        case 'Venue Management':
          this.router.navigate(['/admin/turf']);
          break;
        case 'Compliance':
          this.router.navigate(['/admin/compliance']);
          break;
        case 'Rentals':
          this.router.navigate(['/admin/rental']);
          break;
        case 'Contests':
          this.router.navigate(['/admin/contest']);
          break;
        case 'Offer Management':
          this.router.navigate(['/admin/offer']);
          break;
        case 'Sport Management':
          this.router.navigate(['/admin/sport']);
          break;
        case 'Employee':
          this.router.navigate(['/admin/employee']);
          break;
        case 'CRM':
          this.router.navigate(['/admin/crm']);
          break;
        case 'Withdrawal':
          this.router.navigate(['/admin/withdrawal']);
          break;
        case 'Campaign Management':
          this.router.navigate(['/admin/campaign']);
          break;
        default:
          this.router.navigate(['/admin/dashboard']);
          break;
      }
    }
  }

  fetchDetailViaGet(url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.get(url, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchDetail(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }

  addData(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  deleteData(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  fetchDataFromDB(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  updateDataViaPut(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }


  putMethodWithToken(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  postMethodWithToken(param: any, url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  getMethodWithToken(url: string, httpHeaders: any) {
    return new Promise((resolve) => {
      this._httpClient.get(url, { headers: httpHeaders }).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  putMethod(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.put(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  postMethod(param: any, url: string) {
    return new Promise((resolve) => {
      this._httpClient.post(url, param).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
  getMethod(url: string) {
    return new Promise((resolve) => {
      this._httpClient.get(url).subscribe(
        (data) => resolve(data),
        (err) => resolve(err)
      );
    });
  }
}
